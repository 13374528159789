import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import helpers from "../../../helpers/dateParser";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import { preserveQueryStringParams } from "../../AppLink";

const QuoteSummaryTextArea = ({ name, label, attributes }) => {
  const navigate = useHistory();

  let replacedText;
  let textToDisplay;

  let title = !isNullOrUndefined(attributes) ? attributes.title : null;
  let phoneNo =
    !isNullOrUndefined(attributes) &&
    (attributes.phoneNumber !== null || attributes.phoneNumber !== undefined)
      ? attributes.phoneNumber
      : "";
  let cover =
    !isNullOrUndefined(attributes) &&
    (attributes.cover !== null || attributes.cover !== undefined)
      ? attributes.cover
      : "";
  const coverValue =
    cover !== ""
      ? AppStore.getDescriptionByCode(AppStore.getControlByName(cover), "cover-type")
      : "";
  let timespan = AppStore.getTimespan();
  let listItems =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.items)
      ? attributes.items
      : null;
  let displayedText = label
    .replace("%%DATE%%", helpers.getFriendlyTimeSpanDate(timespan))
    .replace("%%TIME%%", helpers.getFriendlyTimeSpanHours(timespan))
    .replace("%%COVERTYPE%%", coverValue)
    .replace("%%PHONENO%%", phoneNo);

  if (phoneNo !== undefined && phoneNo !== "") {
    replacedText = reactStringReplace(displayedText, phoneNo, (match, i) => (
      <a href={`tel:${match}`} className={`phone extra-pages-phone-anchor`} key={i}>
        <span className={`phone extra-pages-phone`}>{match}</span>
      </a>
    ));
  } else {
    replacedText = displayedText;
  }

  textToDisplay = reactStringReplace(replacedText, "<br />", (match, i) => (
    <br key={"br" + i} />
  ));

  textToDisplay = reactStringReplace(textToDisplay, "%%ASSUMPTIONSLIST%%", (match, i) => (
    <ul key={`listItems_${i}`}>
      {listItems && listItems.split("|").map((item, index) => <li key={item}>{item}</li>)}
    </ul>
  ));

  const backAndPrintBtnsArePresent = !!(
    attributes.showBackButton && attributes.printButton
  );

  return (
    <React.Fragment>
      <div
        className={`quotesummary-buttons-container d-flex justify-content-between d-print-none ${
          backAndPrintBtnsArePresent ? "flex-column flex-sm-row" : ""
        }`}
        style={{ gap: "10px" }}>
        {attributes.showBackButton && (
          <div className="quotesummary-back-btn-container">
            <button
              className="quotesummary-back-btn btn"
              onClick={() => {
                navigate.push(
                  preserveQueryStringParams(
                    `${import.meta.env.VITE_SITE_ROUTE}/${
                      attributes.backRedirectUrl ?? "yourquotes"
                    }`
                  )
                );
              }}>
              {attributes.backButtonText ?? "Back to results"}
            </button>
          </div>
        )}

        {!!attributes.printButton && (
          <div className="quotesummary-print-btn-container">
            <button
              type="button"
              onClick={() => window.print()}
              className="quotesummary-print-btn btn">
              {!!attributes.printButton.icon && (
                <i className={`fa fas fa-${attributes.printButton.icon} mr-2`} />
              )}
              {attributes.printButton.text ?? "Print details"}
            </button>
          </div>
        )}
      </div>
      <div className={`quotesummary-text-area col-12`}>
        {title !== null && (
          <div
            className={`form-group page-basic page-title pt-2 pb-4 title title-${name}`}>
            {title}
          </div>
        )}
        <label className={`label label-${name}`}>{textToDisplay}</label>
      </div>
    </React.Fragment>
  );
};

QuoteSummaryTextArea.propTypes = {
  name: PropTypes.string.isRequired,
};

export default QuoteSummaryTextArea;
