﻿import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Actions, Categories, Messages } from "../../../constants";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import { formatPostcode } from "../../../helpers/addressLookupHelper";
import helpers from "../../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import ClickStreamProvider from "../../../providers/ClickStreamProvider";
import AppStore from "../../../store/AppStore";
import Error from "../../controls/StaticControls/Error.Component";
import Info from "../../controls/StaticControls/Info.Component";
import Label from "../../controls/StaticControls/Label.Component";
import Tooltip from "../../controls/StaticControls/Tooltip.Component";

const AddressLookupInput = ({
  id,
  name,
  label,
  type,
  required,
  handleChildChange,
  handleChange,
  handleBlur,
  handleInteraction,
  currentValue,
  helpMessages,
  infoMessages,
  validation,
  attributes,
  parentName,
}) => {
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const liveValidation = AppStore.liveValidation;

  const labelElement = useRef(null);

  useEffect(() => {
    if (liveValidation && name === "proposer-postcode-manual") {
      labelElement.current.focus();
      labelElement.current.scrollIntoView();

      AppStore.setFormData({ [name]: formatPostcode(currentValue) });
    }
  }, []);

  useEffect(() => {
    if (name === "proposer-postcode-manual") {
      AppStore.setFormData({ "proposer-postcode": currentValue });
    }
  });

  const errorClass = validation && validation.isValid === false ? "error" : "";
  const allowedCharsRegex = new RegExp(
    attributes.allowedCharsRegex !== null || attributes.allowedCharsRegex !== "undefined"
      ? attributes.allowedCharsRegex
      : null
  );

  if (!allowedCharsRegex.test(currentValue)) {
    currentValue = helpers.ReplaceByRegex(name, currentValue);
  }

  if (!parentName && (currentValue === "" || isNullOrUndefined(currentValue)))
    AppStore.setHasChanged(name, false);

  function processInput(event) {
    //Don't trigger live validation if 'Back to address lookup' button is pressed
    const focusedButton =
      event.relatedTarget && event.relatedTarget.name === "button-address-is-manual";

    if (!focusedButton) {
      // format value if postcode control
      if (name === "proposer-postcode-manual") {
        event.target.value = formatPostcode(event.target.value);
      }
      handleBlur(event);
    }

    if (AppStore.getSendFullQuoteData() === "true") {
      ClickStreamProvider.handleClickStreamEvent(
        Categories.QuestionSetCompletion,
        Actions.ContinueQuote,
        event.target.name,
        Messages.EmptyMessage
      );
    }
  }

  const controlComponent = (
    <input
      key={`controlkey-${name}`}
      type={
        attributes.type !== null || attributes.type !== "undefined"
          ? attributes.type
          : type
      }
      className={`form-control control-${errorClass}`}
      data-testid={`test-${name}`}
      id={name}
      name={name}
      required={required}
      onChange={(event) => {
        if (!allowedCharsRegex.test(event.target.value)) {
          event.target.value = helpers.ReplaceByRegex(name, event.target.value);
        }
        if (handleChildChange) handleChildChange(event);
        else handleChange(event);
        helpers.ManipulateControlValuesOnChange(name, event.target.value);
        handleInteraction(event);
      }}
      onKeyDown={(event) => {
        const isAllowedKey =
          event.keyCode === 46 ||
          event.keyCode === 8 ||
          event.keyCode === 37 ||
          event.keyCode === 39 ||
          event.keyCode === 9
            ? true
            : false;

        if (!allowedCharsRegex.test(event.key)) {
          if (!isAllowedKey) {
            event.preventDefault();
          }
        }
      }}
      onBlur={(event) => processInput(event)}
      defaultValue={currentValue}
      maxLength={
        attributes.maxLength !== null || attributes.maxLength !== "undefined"
          ? attributes.maxLength
          : "200"
      }
      placeholder={
        attributes.placeholder !== null || attributes.placeholder !== "undefined"
          ? attributes.placeholder
          : ""
      }
    />
  );

  const componentMapping = {
    label: (
      <Label
        ref={labelElement}
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation ? validation : ""}
      />
    ),
    tooltip: helpMessages ? (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ) : (
      ""
    ),
    info: infoMessages ? (
      <Info
        key={`infokey-${name}`}
        infoMessages={infoMessages != null ? infoMessages.info : ""}
      />
    ) : (
      ""
    ),
    error: (
      <Error
        name={name}
        key={`errorkey-${name}`}
        validation={validation ? validation : ""}
      />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-4 col-md-5 col-sm-12 col-12`,
  };

  return (
    <div
      className={`form-group form-group-container form-group-${name} ${customFormGroupClass}`}>
      <div id={`row-${name}`} className={`row row-${errorClass}`} name={`${errorClass}`}>
        <ControlRowFactory
          controlName={name}
          groupClasses={groupClasses}
          componentMappings={componentMapping}
        />
      </div>
    </div>
  );
};

AddressLookupInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AddressLookupInput;
