import AppStore from "@store/AppStore";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Error from "../../controls/StaticControls/Error.Component";
import Label from "../StaticControls/Label.Component";

const AddressLookupDropdown = ({
  id,
  name,
  label,
  controlValuesList,
  handleChange,
  handleBlur,
  handleChildChange,
  defaultValue,
  validation,
  attributes,
}) => {
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const errorClass = validation && validation.isValid === false ? "error" : "";

  useEffect(() => {
    AppStore.deleteFormData(name);
  }, []);

  return (
    <div
      className={`form-group form-group-container form-group-postcode-address-search ${customFormGroupClass}`}>
      <div id={`row-${name}`} className={`row row-${errorClass} d-block`}>
        <div className="address-dropdown-container col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-3">
          <Label label={label} />
          <select
            className="form-control col-lg-9 col-md-9 col-sm-9 col-11 mx-auto "
            id={id}
            data-testid={`test-${name}`}
            name={name}
            onBlur={(ev) => handleBlur(ev)}
            onChange={(ev) => {
              handleChange(ev);
              handleChildChange(ev);
            }}
            defaultValue={defaultValue}>
            {controlValuesList.map((item) => {
              return (
                <option value={item.key} key={name + "_" + item.key}>
                  {item.description}
                </option>
              );
            })}
          </select>
        </div>
        <Error
          className="next-line col-lg-12 col-md-12 col-sm-12 col-12"
          validation={validation ? validation : ""}
        />
      </div>
    </div>
  );
};

AddressLookupDropdown.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AddressLookupDropdown;
