import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import { vistatsV1Call } from "../../../helpers/vistatsV1Helper";
import ClientLogging from "../../../providers/loggingProvider";
import http from "../../../services/httpService";
import AppStore from "../../../store/AppStore";
import { preserveQueryStringParams } from "../../AppLink";
import PromptPopup from "../PromptPopup.Component";
import AdUnit from "./AdUnit.Component";

const LightBoxPopup = observer(({ currentPageIndex, currentPage, slotName }) => {
  const adConfig = (() => {
    try {
      return AppStore.getAdProperties(
        currentPage.url,
        slotName,
        AppStore.getEntryQueryString()
      );
    } catch (ex) {
      ClientLogging.logError(ex, "LightBoxPopup: ad config");
      return null;
    }
  })();

  const [isAd, setIsAd] = useState(false);
  const controlTypesBlackList = ["Submit", "Button", "Notification", "HiddenInput"];
  const [pageControls, setPageControls] = useState(null);

  const defaultLabel =
    !isNullOrUndefined(adConfig) && !isNullOrUndefined(adConfig.lightbox.messages.default)
      ? adConfig.lightbox.messages.default
      : "You could be missing out on finding your cheapest bike insurance quote";
  const title = "Leaving so soon?";
  const [text, setText] = useState(defaultLabel);
  const [nextPageControlLabel, setNextPageControlLabel] = useState(defaultLabel);

  const secondaryIdleTimeout =
    !isNullOrUndefined(adConfig) && !isNullOrUndefined(adConfig.lightbox)
      ? 1000 * 60 * adConfig.lightbox.secondaryIdleTimeout
      : 999999;
  const mainIdleTimeout =
    !isNullOrUndefined(adConfig) && !isNullOrUndefined(adConfig.lightbox)
      ? 1000 * 60 * adConfig.lightbox.mainIdleTimeout
      : 9999999;
  let secondaryTimer = null;
  let noSessionTimer = null;

  let lastControlInteracted = AppStore.userInteractionState.get("lastControlInteracted");
  let isVisible = AppStore.userInteractionState.get("isLightboxVisible"); //Will also set header "on-top" when true
  let isEnabled =
    AppStore.userInteractionState.get("isLightboxEnabledOnPage") &&
    !isNullOrUndefined(adConfig);

  const lightboxVersion = AppStore.getUserJourneySettings().lightbox
    ? AppStore.getUserJourneySettings().lightbox.version ?? null
    : "v1";

  useIdleTimer({
    disabled: !isEnabled,
    timeout: mainIdleTimeout,
    debounce: 250,
    onIdle: () => {
      if (isEnabled) {
        AppStore.setUserInteractionState({ ["isLightboxVisible"]: true });

        vistatsV1Call(
          `${slotName}`,
          currentPage.url,
          () => `Page=${currentPage.url}&Interaction=Open`,
          () => `${slotName}`
        );
      }
    },
  });

  const initiateNoSessionTimer = () => {
    //TODO: get session duration from config and calculate this timer based on that
    noSessionTimer = setTimeout(() => {
      window.location.replace(
        preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/nosession`)
      );
    }, 1000 * 60 * 25);
  };

  const initiateSecondaryTimer = () => {
    secondaryTimer = setTimeout(() => {
      const endpoint = `/${import.meta.env.VITE_QUOTES_API_ENDPOINT}/failedQuote`;
      http
        .post(endpoint, {
          appHost: import.meta.env.VITE_BASE_URL,
          appRoute: import.meta.env.VITE_SITE_ROUTE,
          recallSuffix: `/default/`,
          collectedQuoteData: AppStore.getFormData(),
        })
        .then(initiateNoSessionTimer())
        .catch((error) => {
          ClientLogging.logError(error, "Failed Quote");
        });
    }, secondaryIdleTimeout);
  };

  const handleModalClose = () => {
    AppStore.setUserInteractionState({ ["isLightboxVisible"]: false });

    if (secondaryTimer) {
      clearTimeout(secondaryTimer);
    }
    if (noSessionTimer) {
      clearTimeout(noSessionTimer);
    }

    vistatsV1Call(
      `${slotName}`,
      currentPage.url,
      () => `Page=${currentPage.url}&Interaction=Continue`,
      () => `${slotName}`
    );
  };

  const resetQuote = () => {
    AppStore.clearFormData();
    AppStore.setUserInteractionState({ ["isLightboxVisible"]: false });

    if (secondaryTimer) {
      clearTimeout(secondaryTimer);
    }
    if (noSessionTimer) {
      clearTimeout(noSessionTimer);
    }

    vistatsV1Call(
      `${slotName}`,
      currentPage.url,
      () => `Page=${currentPage.url}&Interaction=ResetQuote`,
      () => `${slotName}`
    );

    window.location.replace(
      preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/aboutyou`)
    );
  };

  useEffect(() => {
    if (
      !isNullOrUndefined(adConfig) &&
      !isNullOrUndefined(AppStore.getPages()[currentPageIndex]) &&
      adConfig.pages.includes(AppStore.getPages()[currentPageIndex].url)
    ) {
      AppStore.setUserInteractionState({ ["isLightboxEnabledOnPage"]: true });
      setPageControls(
        !isNullOrUndefined(AppStore.pageComponents[currentPageIndex].components[0])
          ? AppStore.pageComponents[currentPageIndex].components[0].Controls
          : null
      );
    } else {
      AppStore.setUserInteractionState({
        ["isLightboxEnabledOnPage"]: false,
      });
    }
  }, []);

  useEffect(() => {
    if (isVisible && isEnabled) {
      initiateSecondaryTimer();
    }
  }, [isVisible]);

  useEffect(() => {
    if (isEnabled) {
      let controls =
        !isNullOrUndefined(AppStore.pageComponents[currentPageIndex].components) &&
        !isNullOrUndefined(AppStore.pageComponents[currentPageIndex].components[0])
          ? AppStore.pageComponents[currentPageIndex].components[0].Controls
          : null;
      setPageControls(controls);
      if (!isNullOrUndefined(controls)) setNewLabel(controls, 0);
    }
  }, [currentPageIndex, isEnabled]);

  const hasDefaultValue = (control) => {
    return !isNullOrUndefined(control.DefaultValue) ? control.DefaultValue !== "" : false;
  };

  const isSpecificCase = (control) => {
    //Allow bike model to be selected since it's visible only after all bike data is entered
    return control.Name === "bike-model";
  };

  const canBeSelected = (control) => {
    const visibleControls = toJS(AppStore.getVisibilityControls());

    return (
      !controlTypesBlackList.includes(control.Type) &&
      visibleControls.get(control.Name) === true &&
      !hasDefaultValue(control)
    );
  };

  const findSuitableControl = (controls, index) => {
    for (index; index < controls.length; index++) {
      const control = controls[index];

      if (isSpecificCase(control) || canBeSelected(control)) return control;
    }
    return null;
  };

  const setNewLabel = (controls, currentIndex) => {
    let suitableControl = findSuitableControl(controls, currentIndex);
    if (!isNullOrUndefined(suitableControl)) {
      let newText = adConfig.lightbox.messages[suitableControl.Name];
      setText(!isNullOrUndefined(newText) ? newText : defaultLabel);
    } else {
      setText(defaultLabel);
    }
  };

  useEffect(() => {
    if (isEnabled) {
      if (!isNullOrUndefined(pageControls) && !isNullOrUndefined(lastControlInteracted)) {
        const control = toJS(AppStore.userInteractionState).get("lastControlInteracted");
        const searchName = control?.ParentName || control?.Name;

        const index = pageControls.indexOf(
          pageControls.find((c) => c.Name === searchName)
        );

        setNewLabel(pageControls, index + 1);
      }
    }
  }, [lastControlInteracted]);

  return isEnabled ? (
    <>
      <PromptPopup
        isOpen={isVisible && !isAd}
        id="lightbox-ad"
        handleClose={handleModalClose}
        hasCloseBtn
        closeOnOutsideClick={false}
        text={text}
        title={title}
        actionBtn={{
          id: "lightbox-continue-btn",
          name: "lightbox-continue-btn",
          label: "Continue my quote",
          icon: lightboxVersion === "v2" ? "arrow-right" : null,
          onClick: handleModalClose,
        }}
        cancelBtn={{
          id: "lightbox-reset-btn",
          name: "lightbox-reset-btn",
          label: "Start my quote again",
          icon: lightboxVersion === "v2" ? "arrow-left" : null,
          onClick: resetQuote,
        }}
        customClassMapping={{
          container: "lightbox-container overlay",
          main: "lightbox-main",
          head: "lightbox-head",
          closeBtn: "lightbox-btn-close close",
          closeBtnIcon: "lightbox-btn-close-icon",
          content: "content",
          title: "lightbox-title",
          text: "lightbox-text",
          footer: "lightbox-footer",
          actionBtn: "lightbox-btn continue",
          cancelBtn: "lightbox-btn reset",
        }}
      />
      {isVisible && isAd && (
        <div className={"dfp-qhb-popup d-flex flex-column overlay is-active"}>
          <div className="lightbox-ad">
            <AdUnit
              currentPage={AppStore.pageComponents[currentPageIndex]}
              slotName="LIGHTBOX_DEFAULT"
              cssClass="mpu-one"
            />
          </div>
        </div>
      )}
    </>
  ) : null;
});

export default LightBoxPopup;
