import config from "../config.json";
import { cookies } from "../helpers/cookies";
import ClientLogging from "../providers/loggingProvider";

export default {
  load: () => {
    try {
      return cookies.get(config.cookieName);
    } catch (ex) {
      ClientLogging.logError(ex, "Session Service - Update Cookie Token: ");
    }
  },

  loadxsrf: () => {
    return cookies.get(config.cookieNameCsrf);
  },

  update: (data) => {
    try {
      cookies.set(config.cookieName, data, { secure: true, path: "/" });
    } catch (ex) {
      ClientLogging.logError(ex, "Session Service - Update Cookie Token: ");
    }
  },
  updatecsrf: (data) => {
    try {
      cookies.set(config.cookieNameCsrf, data, { secure: true });
    } catch (ex) {
      ClientLogging.logError(ex, "Session Service - Update Cookie Token: ");
    }
  },
};
