import React from "react";
import MultiLazyLoading from "../../components/MultiLazyLoading";

import AddressLookup from "../../components/controls/AddressLookup/AddressLookup.Component";
import AddressLookupCustom from "../../components/controls/AddressLookup/AddressLookupCustom.Component";
import AddressLookupDropdown from "../../components/controls/AddressLookup/AddressLookupDropdown";
import AddressLookupInput from "../../components/controls/AddressLookup/AddressLookupInput";
import Button from "../../components/controls/Button.Component";
import ButtonBanner from "../../components/controls/ButtonFooterBanner";
import Checkbox from "../../components/controls/Checkbox.Component";
import CheckboxSingle from "../../components/controls/CheckboxSingle.Component";
import CustomCheckbox from "../../components/controls/CustomCheckbox.Component";
import CustomRadio from "../../components/controls/CustomRadio.Component";
import Date from "../../components/controls/Dates/Date.Component";
import Dropdown from "../../components/controls/Dropdown.Component";
import DropdownSingle from "../../components/controls/DropdownSingle.Component";
import Optin from "../../components/controls/Gdpr/OptIn.Component";
import HiddenInput from "../../components/controls/HiddenInput.Component";
import Input from "../../components/controls/Input.Component";
import NumericalInput from "../../components/controls/NumericalInput.Component";
import Radio from "../../components/controls/Radio.Component";
import CustomList from "../../components/controls/StaticControls/CustomList.Component";
import ExtraPagesTextArea from "../../components/controls/StaticControls/ExtraPagesTextArea.Component";
import Info from "../../components/controls/StaticControls/Info.Component";
import Label from "../../components/controls/StaticControls/Label.Component";
import MessageBox from "../../components/controls/StaticControls/MessageBox.Component";
import Notification from "../../components/controls/StaticControls/Notification.Component";
import QuoteSummaryTextArea from "../../components/controls/StaticControls/QuoteSummaryTextArea.Component";
import TextArea from "../../components/controls/StaticControls/TextArea.Component";
import Submit from "../../components/controls/Submit.Component";

import FormRowAd from "../../components/controls/Ads/FormRowAd.Component";
import LightBoxPopup from "../../components/controls/Ads/LightBoxPopup.Component";
import DateMonthYear from "../../components/controls/Dates/DateMonthYear.Component";
import DatePolicyToStart from "../../components/controls/Dates/DatePolicyToStart.Component";
import MarketingStatement from "../../components/controls/Gdpr/MarketingStatement.Component";
import LicenceNumber from "../../components/controls/LicenceNumber.Component";
import LicenceNumberCustom from "../../components/controls/LicenceNumberCustom.Component";
import QhbPopup from "../../components/controls/QhbPopup.Component";
import RadioOptIn from "../../components/controls/RadioOptIn.Component";

const [
  SecondOccupationLookup,
  LicenceTime,
  OccupationStudent,
  AutoComplete,
  OccupationLoopkup,
  DatePicker,
] = MultiLazyLoading([
  () =>
    import(
      "../../components/controls/OccupationAndIndustries/SecondOccupationLookup.Component"
    ),
  () => import("../../components/controls/LicenceTime.Component"),
  () =>
    import(
      "../../components/controls/OccupationAndIndustries/OccupationStudent.Component"
    ),
  () =>
    import("../../components/controls/OccupationAndIndustries/AutoComplete.Component"),
  () =>
    import(
      "../../components/controls/OccupationAndIndustries/OccupationLookup.Component"
    ),
  () => import("../../components/controls/Dates/DatePicker.Component"),
]);

const SlideToggleContent = React.lazy(() =>
  import("../../components/controls/SlideToggleContent.Component")
);
const VehicleLookup = React.lazy(() =>
  import("../../components/controls/VehicleLookup/VehicleLookup.Component")
);
const VehicleLookupSearch = React.lazy(() =>
  import("../../components/controls/VehicleLookup/VehicleLookupSearch.Component")
);
const VehicleLookupDropdown = React.lazy(() =>
  import("../../components/controls/VehicleLookup/VehicleLookupDropdown.Component")
);
const VehicleLookupModel = React.lazy(() =>
  import("../../components/controls/VehicleLookup/VehicleLookupModel.Component")
);
const VehicleLookupRadio = React.lazy(() =>
  import("../../components/controls/VehicleLookup/VehicleLookupRadio.Component")
);
const RetrieveInput = React.lazy(() =>
  import("../../components/controls/RetrieveQuote/RetrieveInput")
);
const RetrieveQuote = React.lazy(() =>
  import("../../components/controls/RetrieveQuote/RetrieveQuote.Component")
);
const BuyCheckInput = React.lazy(() =>
  import("../../components/controls/Buy/BuyCheckInput")
);
const PostcodeCheckInput = React.lazy(() =>
  import("../../components/controls/RetrieveQuote/PostcodeCheckInput")
);
const DataListItem = React.lazy(() =>
  import("../../components/controls/ClaimsAndConvictions/DataListItem.Component")
);
const CustomDataListItem = React.lazy(() =>
  import("../../components/controls/ClaimsAndConvictions/CustomDataListItem.Component")
);
const DataListAdditionalDrivers = React.lazy(() =>
  import(
    "../../components/controls/ClaimsAndConvictions/DataListAdditionalDrivers.Component"
  )
);
const CustomDataListAdditionalDrivers = React.lazy(() =>
  import(
    "../../components/controls/ClaimsAndConvictions/CustomDataListAdditionalDrivers.Component"
  )
);
const Responses = React.lazy(() =>
  import("../../components/controls/BrokerResults/Responses.Component")
);
const ResultsTopArea = React.lazy(() =>
  import("../../components/controls/BrokerResults/ResultsTopArea.Component")
);
const ResultsButtonsArea = React.lazy(() =>
  import("../../components/controls/BrokerResults/ResultsButtonsArea.Component")
);
const QuoteReference = React.lazy(() =>
  import("../../components/controls/BrokerResults/QuoteReference.Component")
);

const OffenceCodeDropdown = React.lazy(() =>
  import("../../components/controls/OffenceCode/OffenceCodeDropdown.Component")
);
const TypeOfAlcoholDropdown = React.lazy(() =>
  import("../../components/controls/OffenceCode/TypeOfAlcoholDropdown.Component")
);
const LevelOfReadingInput = React.lazy(() =>
  import("../../components/controls/OffenceCode/LevelOfReadingInput.Component")
);
const BuyRedirect = React.lazy(() =>
  import("../../components/controls/Buy/BuyRedirect.Component")
);
const QuoteSummaryContent = React.lazy(() =>
  import("../../components/controls/QuoteSummary/QuoteSummaryContent.Component")
);
const TermsPrivacyContent = React.lazy(() =>
  import("../../components/controls/StaticControls/TermsPrivacyContent.Component")
);
const UnsubscribeOptions = React.lazy(() =>
  import("../../components/controls/Gdpr/UnsubscribeOptions")
);

//bike product specific components
const MultiRadio = React.lazy(() =>
  import("../../components/controls/MultiRadio.Component")
);
const [
  BikeAdditionalProposer,
  DataListClaims,
  CustomDataListClaims,
  DataListConvictions,
  CustomDataListConvictions,
] = MultiLazyLoading([
  () => import("../../components/controls/Bike/BikeAdditionalProposer.Component"),
  () => import("../../components/controls/ClaimsAndConvictions/DataListClaims.Component"),
  () =>
    import(
      "../../components/controls/ClaimsAndConvictions/CustomDataListClaims.Component"
    ),
  () =>
    import(
      "../../components/controls/ClaimsAndConvictions/DataListConvictions.Component"
    ),
  () =>
    import(
      "../../components/controls/ClaimsAndConvictions/CustomDataListConvictions.Component"
    ),
]);

const [
  BikeVehicleLookup,
  BikeVehicleLookupSearch,
  BikeVehicleLookupSearchNQS,
  BikeVehicleLookupDropdown,
  BikeVehicleLookupInput,
  BikeVehicleLookupModel,
  BikeVehicleLookupInfoCard,
  BikeResults,
  DataListBikeAdditionalRider,
  CustomRadioStorage,
  MarketingLightBox,
  CustomCheckboxControl,
  OptionalExtras,
  ResultsFilterPanel,
] = MultiLazyLoading([
  () => import("../../components/controls/Bike/VehicleLookup/VehicleLookup.Component"),
  () =>
    import("../../components/controls/Bike/VehicleLookup/VehicleLookupSearch.Component"),
  () =>
    import(
      "../../components/controls/Bike/VehicleLookup/VehicleLookupSearchNQS.Component"
    ),
  () =>
    import(
      "../../components/controls/Bike/VehicleLookup/VehicleLookupDropdown.Component"
    ),
  () =>
    import("../../components/controls/Bike/VehicleLookup/VehicleLookupInput.Component"),
  () =>
    import("../../components/controls/Bike/VehicleLookup/VehicleLookupModel.Component"),
  () =>
    import(
      "../../components/controls/Bike/VehicleLookup/VehicleLookupInfoCard.Component"
    ),
  () => import("../../components/controls/Bike/BrokerResults/Responses.Component"),
  () => import("../../components/controls/Bike/DataListBikeAdditionalRider.Component"),
  () => import("../../components/controls/Bike/CustomRadioStorageOvernight.Component"),
  () => import("../../components/controls/Gdpr/MarketingLightBox.Component"),
  () => import("../../components/controls/Gdpr/CustomCheckboxControl.component"),
  () => import("../../components/controls/Bike/OptionalExtras.Component"),
  () =>
    import(
      "../../components/controls/Bike/BrokerResults/AddonFilters/ResultsFilterPanel.Component"
    ),
]);

//car product specific components
const CarUsage = React.lazy(() =>
  import("../../components/controls/Car/VehicleUsageDropdown.Component")
);
const CarVehicleLookup = React.lazy(() =>
  import("../../components/controls/Car/VehicleLookup/VehicleLookup.Component")
);
const CarVehicleLookupSearch = React.lazy(() =>
  import("../../components/controls/Car/VehicleLookup/VehicleLookupSearch.Component")
);
const CarVehicleLookupDropdown = React.lazy(() =>
  import("../../components/controls/Car/VehicleLookup/VehicleLookupDropdown.Component")
);
const CarVehicleLookupInput = React.lazy(() =>
  import("../../components/controls/Car/VehicleLookup/VehicleLookupInput.Component")
);
const CarVehicleLookupModel = React.lazy(() =>
  import("../../components/controls/Car/VehicleLookup/VehicleLookupModel.Component")
);
const CarVehicleLookupRadio = React.lazy(() =>
  import("../../components/controls/Car/VehicleLookup/VehicleLookupRadio.Component")
);
const ModificationsPanel = React.lazy(() =>
  import("../../components/controls/Car/Modifications/ModificationsPanel.Component")
);
const ModificationsList = React.lazy(() =>
  import("../../components/controls/Car/Modifications/ModificationsList.Component")
);
const ModificationsCheckbox = React.lazy(() =>
  import("../../components/controls/Car/Modifications/ModificationsCheckbox.Component")
);
const ModificationsRadio = React.lazy(() =>
  import("../../components/controls/Car/Modifications/ModificationsRadio.Component")
);
const CarResults = React.lazy(() =>
  import("../../components/controls/Car/BrokerResults/Responses.Component")
);
const CarDataListClaims = React.lazy(() =>
  import("../../components/controls/Car/ClaimsAndConvictions/DataListClaims.Component")
);
const CarDataListItem = React.lazy(() =>
  import("../../components/controls/Car/ClaimsAndConvictions/DataListItem.Component")
);
const CarDataListAdditionalDrivers = React.lazy(() =>
  import(
    "../../components/controls/Car/ClaimsAndConvictions/DataListAdditionalDrivers.Component"
  )
);
const CarDataListConvictions = React.lazy(() =>
  import(
    "../../components/controls/Car/ClaimsAndConvictions/DataListConvictions.Component"
  )
);

const MotorhomeVehicleMakeAutocomplete = React.lazy(() =>
  import("../../components/controls/Motorhome/VehicleLookup/AutoComplete.Component")
);
const MotorhomeVehicleBodyType = React.lazy(() =>
  import(
    "../../components/controls/Motorhome/VehicleBodyType/VehicleBodyTypeDropdown.Component"
  )
);
const MotorhomeVehicleBodyTypePanel = React.lazy(() =>
  import(
    "../../components/controls/Motorhome/VehicleBodyType/VehicleBodyTypePanel.Component"
  )
);
const MotorhomeVehicleBodyTypeList = React.lazy(() =>
  import(
    "../../components/controls/Motorhome/VehicleBodyType/VehicleBodyTypeList.Component"
  )
);
const MotorhomeYearManufacture = React.lazy(() =>
  import("../../components/controls/Motorhome/VehicleLookup/YearManufacture.Component")
);
const MotorhomeOvernightStorageDropdown = React.lazy(() =>
  import("../../components/controls/Motorhome/OvernightStorageDropdown.Component")
);
const MotorhomeResults = React.lazy(() =>
  import("../../components/controls/Motorhome/BrokerResults/Responses.Component")
);
const MotorhomeDataListClaims = React.lazy(() =>
  import(
    "../../components/controls/Motorhome/ClaimsAndConvictions/DataListClaims.Component"
  )
);
const MotorhomeDataListItem = React.lazy(() =>
  import(
    "../../components/controls/Motorhome/ClaimsAndConvictions/DataListItem.Component"
  )
);
const MotorhomeDataListConvictions = React.lazy(() =>
  import(
    "../../components/controls/Motorhome/ClaimsAndConvictions/DataListConvictions.Component"
  )
);
const MotorhomeCustomNoSessionPage = React.lazy(() =>
  import("../../components/controls/Motorhome/CustomNoSessionPage.Component")
);

const MotorhomeDataListAdditionalDrivers = React.lazy(() =>
  import(
    "../../components/controls/Motorhome/ClaimsAndConvictions/DataListAdditionalDrivers.Component"
  )
);

//van product specific components
const VanUsage = React.lazy(() => import("../../components/controls/Van/VehicleUsageDropdown.Component"));
const VanVehicleLookup = React.lazy(() => import("../../components/controls/Van/VehicleLookup/VehicleLookup.Component"));
const VanVehicleLookupSearch = React.lazy(() => import("../../components/controls/Van/VehicleLookup/VehicleLookupSearch.Component"));
const VanVehicleLookupDropdown = React.lazy(() => import("../../components/controls/Van/VehicleLookup/VehicleLookupDropdown.Component"));
const VanVehicleLookupInput = React.lazy(() => import("../../components/controls/Van/VehicleLookup/VehicleLookupInput.Component"));
const VanVehicleLookupModel = React.lazy(() => import("../../components/controls/Van/VehicleLookup/VehicleLookupModel.Component"));
const VanVehicleLookupRadio = React.lazy(() => import("../../components/controls/Van/VehicleLookup/VehicleLookupRadio.Component"));
const VanResults = React.lazy(() => import("../../components/controls/Van/BrokerResults/Responses.Component"));
const VanDataListClaims = React.lazy(() => import("../../components/controls/Van/ClaimsAndConvictions/DataListClaims.Component"));
const VanDataListItem = React.lazy(() => import("../../components/controls/Van/ClaimsAndConvictions/DataListItem.Component"));
const VanDataListAdditionalDrivers = React.lazy(() => import("../../components/controls/Van/ClaimsAndConvictions/DataListAdditionalDrivers.Component"));
const VanDataListConvictions = React.lazy(() => import("../../components/controls/Van/ClaimsAndConvictions/DataListConvictions.Component"));
const VanDateMonthYear = React.lazy(() => import("../../components/controls/Van/Dates/DateMonthYear.Component"));

//caravan product specific 
const CaravanDataListClaims = React.lazy(() => import("../../components/controls/Caravan/ClaimsAndConvictions/DataListClaims.Component"));
const CaravanVehicleLookupDropdown = React.lazy(() => import("../../components/controls/Caravan/VehicleLookup/VehicleLookupDropdown.Component"));
const CaravanVehicleLookupModel = React.lazy(() => import("../../components/controls/Caravan/VehicleLookup/VehicleLookupModel.Component"));
const CaravanResults = React.lazy(() => import("../../components/controls/Caravan/BrokerResults/Responses.Component"));
const CaravanMakeDropdown = React.lazy(() => import("../../components/controls/Caravan/CaravanMakeDropdown.Component"));

export default {
  Input: Input,
  HiddenInput: HiddenInput,
  NumericalInput: NumericalInput,
  Dropdown: Dropdown,
  Radio: Radio,
  DropdownSingle: DropdownSingle,
  CustomRadio: CustomRadio,
  Button: Button,
  Submit: Submit,
  OccupationLookup: OccupationLoopkup,
  OccupationStudent: OccupationStudent,
  SecondOccupationLookup: SecondOccupationLookup,
  Date: Date,
  MessageBox: MessageBox,
  Checkbox: Checkbox,
  CustomCheckbox: CustomCheckbox,
  Label: Label,
  Info: Info,
  OptIn: Optin,
  RadioOptIn: RadioOptIn,
  Notification: Notification,
  TextArea: TextArea,
  ExtraPagesTextArea: ExtraPagesTextArea,
  DatePicker: DatePicker,
  AddressLookup: AddressLookup,
  AddressLookupCustom: AddressLookupCustom,
  ButtonBanner: ButtonBanner,
  AutoComplete: AutoComplete,
  LicenceTime: LicenceTime,
  LicenceNumber: LicenceNumber,
  LicenceNumberCustom: LicenceNumberCustom,
  MarketingStatement: MarketingStatement,
  MarketingLightBox: MarketingLightBox,
  CustomCheckboxControl: CustomCheckboxControl,
  DateMonthYear: DateMonthYear,
  DatePolicyToStart: DatePolicyToStart,
  SlideToggleContent: SlideToggleContent,
  CheckboxSingle: CheckboxSingle,
  VehicleLookup: VehicleLookup,
  VehicleLookupSearch: VehicleLookupSearch,
  VehicleLookupDropdown: VehicleLookupDropdown,
  VehicleLookupModel: VehicleLookupModel,
  VehicleLookupRadio: VehicleLookupRadio,
  AddressLookupDropdown: AddressLookupDropdown,
  AddressLookupInput: AddressLookupInput,
  DataListClaims: DataListClaims,
  CustomDataListClaims: CustomDataListClaims,
  DataListConvictions: DataListConvictions,
  CustomDataListConvictions: CustomDataListConvictions,
  DataListAdditionalDrivers: DataListAdditionalDrivers,
  CustomDataListAdditionalDrivers: CustomDataListAdditionalDrivers,
  DataListItem: DataListItem,
  CustomDataListItem: CustomDataListItem,
  Responses: Responses,
  RetrieveQuote: RetrieveQuote,
  ResultsTopArea: ResultsTopArea,
  ResultsButtonsArea: ResultsButtonsArea,
  QuoteReference: QuoteReference,
  CustomList: CustomList,
  OffenceCodeDropdown: OffenceCodeDropdown,
  LevelOfReadingInput: LevelOfReadingInput,
  TypeOfAlcoholDropdown: TypeOfAlcoholDropdown,
  RetrieveInput: RetrieveInput,
  BuyCheckInput: BuyCheckInput,
  PostcodeCheckInput: PostcodeCheckInput,
  BuyRedirect: BuyRedirect,
  QuoteSummaryContent: QuoteSummaryContent,
  QuoteSummaryTextArea: QuoteSummaryTextArea,
  UnsubscribeOptions: UnsubscribeOptions,
  TermsPrivacyContent: TermsPrivacyContent,
  QhbPopup: QhbPopup,
  LightBoxPopup: LightBoxPopup,
  FormRowAd: FormRowAd,
  //bike product specific components
  MultiRadio: MultiRadio,
  BikeAdditionalProposer: BikeAdditionalProposer,
  BikeVehicleLookup: BikeVehicleLookup,
  BikeVehicleLookupSearch: BikeVehicleLookupSearch,
  BikeVehicleLookupSearchNQS: BikeVehicleLookupSearchNQS,
  BikeVehicleLookupDropdown: BikeVehicleLookupDropdown,
  BikeVehicleLookupInput: BikeVehicleLookupInput,
  BikeVehicleLookupModel: BikeVehicleLookupModel,
  BikeVehicleLookupInfoCard: BikeVehicleLookupInfoCard,
  BikeResults: BikeResults,
  DataListBikeAdditionalRider: DataListBikeAdditionalRider,
  CustomRadioStorage: CustomRadioStorage,
  OptionalExtras: OptionalExtras,
  ResultsFilterPanel: ResultsFilterPanel,
  //car product specific components
  CarUsage: CarUsage,
  CarVehicleLookup: CarVehicleLookup,
  CarVehicleLookupSearch: CarVehicleLookupSearch,
  CarVehicleLookupDropdown: CarVehicleLookupDropdown,
  CarVehicleLookupInput: CarVehicleLookupInput,
  CarVehicleLookupModel: CarVehicleLookupModel,
  CarVehicleLookupRadio: CarVehicleLookupRadio,
  ModificationsPanel: ModificationsPanel,
  ModificationsList: ModificationsList,
  ModificationsCheckbox: ModificationsCheckbox,
  ModificationsRadio: ModificationsRadio,
  CarResults: CarResults,
  CarDataListClaims: CarDataListClaims,
  CarDataListConvictions: CarDataListConvictions,
  CarDataListAdditionalDrivers: CarDataListAdditionalDrivers,
  CarDataListItem: CarDataListItem,
  //motorhome product specific components
  MotorhomeVehicleMakeAutocomplete: MotorhomeVehicleMakeAutocomplete,
  MotorhomeVehicleBodyType: MotorhomeVehicleBodyType,
  MotorhomeVehicleBodyTypePanel: MotorhomeVehicleBodyTypePanel,
  MotorhomeVehicleBodyTypeList: MotorhomeVehicleBodyTypeList,
  MotorhomeYearManufacture: MotorhomeYearManufacture,
  MotorhomeOvernightStorageDropdown: MotorhomeOvernightStorageDropdown,
  MotorhomeResults: MotorhomeResults,
  MotorhomeDataListClaims: MotorhomeDataListClaims,
  MotorhomeDataListConvictions: MotorhomeDataListConvictions,
  MotorhomeCustomNoSessionPage: MotorhomeCustomNoSessionPage,
  MotorhomeDataListAdditionalDrivers: MotorhomeDataListAdditionalDrivers,
  MotorhomeDataListItem: MotorhomeDataListItem,
  //van product specific components
  VanUsage: VanUsage,
  VanVehicleLookup: VanVehicleLookup,
  VanVehicleLookupSearch: VanVehicleLookupSearch,
  VanVehicleLookupDropdown: VanVehicleLookupDropdown,
  VanVehicleLookupInput: VanVehicleLookupInput,
  VanVehicleLookupModel: VanVehicleLookupModel,
  VanVehicleLookupRadio: VanVehicleLookupRadio,
  VanResults: VanResults,
  VanDataListClaims: VanDataListClaims,
  VanDataListConvictions: VanDataListConvictions,
  VanDataListAdditionalDrivers: VanDataListAdditionalDrivers,
  VanDataListItem: VanDataListItem,
  VanDateMonthYear: VanDateMonthYear,
   //caravan product specific components
  CaravanDataListClaims: CaravanDataListClaims,
  CaravanVehicleLookupDropdown: CaravanVehicleLookupDropdown,
  CaravanVehicleLookupModel: CaravanVehicleLookupModel,
  CaravanMakeDropdown: CaravanMakeDropdown,
  CaravanResults: CaravanResults,
};
